import React from "react";
import styled, { keyframes } from "styled-components";
import GoogleMapReact from "google-map-react";

import { device } from "../../utils";
import iconPin from "../../assets/image/svg/map-marker.svg";

const keyBounce = keyframes`

  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }

`;

const MapStyled = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 50px;
  overflow: 'hidden';

  .pin {
    display: flex;
    align-items: center;
    width: 30px;
    color: var(--main-blue);
    animation: ${keyBounce} 0.5s infinite alternate;
  }

  @media ${device.lg} {
    margin-top: 0px;
    position: relative;
    top: 0;
    right: 0;
    height: 500px;
    width: 100%;
  }
`;

const LocationPin = () => (
  <div className="pin">
    <img src={iconPin} className="pin-icon" alt="" style={{ height: 30}}/>
  </div>
);

const MapGoogle = () => {
  const location = {
    lat: 5.6703092,
    lng: -0.184066
  };

  return (
    <>
      <MapStyled>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `AIzaSyBmGmeot5jcjdaJTvfCmQPfzeoG_pABeWo` }}
          defaultCenter={location}
          defaultZoom={12}
          style={{width: '100%', height: 500}}
          className="h-100 w-100 rounded-8"
        >
          <LocationPin lat={location.lat} lng={location.lng} />
        </GoogleMapReact>
      </MapStyled>
    </>
  );
};

export default MapGoogle;

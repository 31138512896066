import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from '../sections/contact/Hero';
import Content from '../sections/contact/Content';

const Contact = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}
      >
        <Hero />
        <Content />
      </PageWrapper>
    </>
  );
};
export default Contact;
